<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        檔案下載
      </h5>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headersContent"
        :key="`headersContent${i}`"
        :title="item.name"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <div v-for="(attachment, i) in ['附件一', '附件二', '附件三', '附件四', '附件五']" :key="i">
      <main
        class="dtc-grid-header2"
        style="color:#39312E;"
        :style="i % 2 == 0 ? 'background-color:#ffffff;' : 'background-color: #efefef;'"
      >
        <div style="padding: 0px 0 0 90px; -webkit-box-orient: horizontal;">
          <b-button
            variant="success"
            @click="getExcel(attachment)"
            size="sm"
            class="mr-2"
            >下載</b-button
          >
        </div>
        <div>
          衛生所缺額管理({{ attachment }})
        </div>
      </main>
    </div>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <!--div>
      <b-modal
        id="editModal"
        :title="`${modalType == 1 ? '新增' : '修改'}公告資料`"
        size="lg"
      >
        <div class="modal-grid-2">
          <b-input-group prepend="主旨" style="grid-column: 1/-1;">
            <b-input
              style="padding-right:28px"
              v-model="modalItem.Subject"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="備註" style="grid-column: 1/-1;" class="mt-2">
            <b-input v-model="modalItem.Remark"></b-input>
          </b-input-group>
          <b-input-group prepend="上線時間" class="mt-2">
            <date-picker-tw
              :time="modalItem.StartDate"
              @update="(t) => (modalItem.StartDate = t)"
              :class="modalItem.isForever ? 'avoid-clicks' : ''"
            ></date-picker-tw>
          </b-input-group>

          <b-input-group prepend="下線時間" class="mt-2">
            <date-picker-tw
              :time="modalItem.EndDate"
              @update="(t) => (modalItem.EndDate = t)"
              :class="modalItem.isForever ? 'avoid-clicks' : ''"
            ></date-picker-tw>
          </b-input-group>
          <div class="mt-2" style="grid-column: 1/-1">
            <quill-editor
              v-model="modalItem.Content"
              ref="myQuillEditor"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
            >
            </quill-editor>
          </div>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editModal')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="saveModal"
              >儲存</b-button
            >
          </div>
        </template>
      </b-modal>
    </div-->
  </section>
</template>

<script>
import buildQuery from "odata-query";

// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import {
  domainObject,
} from "@/constant.js";

const headersContent = [
  { name: "操作", key: "", sortDesc: null },
  { name: "檔案名稱", key: "", sortDesc: null },
];

const rows = [10, 20, 50];

export default {
  name: "downarea",
  data() {
    return {
      //main-content
      headersContent,
      items: [],

      //footer
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      rows,
      totalCountStr: "",
      domainObject,
      //modal
      modalItem: {
        Subject: "",
        Content: "",
        Remark: "",
        StartDate: "",
        EndDate: "",
        Creater: "",
        CreateTime: "",
        Update: "",
        UpdateTime: "",
        HealthCode: "",
      },
      modalType: 1,

      //search
      searchSubject: "",
      searchRemark: "",
      searchUnit: null,

      //searchOptions or ModalOptions

      content: "<h2></h2>",
      editorOption: {
        // some quill options
      },
      healthCodeOptions: [],
    };
  },
  components: {
    quillEditor,
    Treeselect,
  },
  computed: {
    healthCodeCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const opsHpitalArr = JSON.parse(sessionStorage.getItem("opsHpital"));
      const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
      const roleHealthBureauArr = JSON.parse(
        sessionStorage.getItem("roleHealthBureau")
      );
      const roleHealthCenter = JSON.parse(
        sessionStorage.getItem("roleHealthCenter")
      );
      const allArr = [
        ...opsHpitalArr,
        ...stuChoolArr,
        ...roleHealthBureauArr,
        ...roleHealthCenter,
      ];
      return allArr.includes(loginHealthCode);
    },
  },

  methods: {
    async getExcel(attachment) {
      const url = `Questionnaire/DownloadQuestionnaire?attachment=${attachment}`;
      console.log(attachment);
      try {
        const res = await window.axios.get(url); 
        console.log(res);    
        const excelUrl = `${this.domainObject.platformOriginal}${res}`;
        console.log(excelUrl);
        window.open(excelUrl);
        this.$bvToast.toast(`下載成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (error) {
        this.$bvToast.toast(error, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersContent.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });

      this.getData();
    },
    async getData() {
      let healthCode = "";

      if (this.isRoleHealthCenter) {
        healthCode = sessionStorage.getItem("darcok").replace(/\"/g, "");
      }

      if (this.isRoleHealthBureauCheck) {
        const centerCode = sessionStorage.getItem("darcok").replace(/\"/g, "");
        const centerPlace = this.allHealthBureauOption.find(
          (s) => s.No == centerCode
        ).Name;
        let placeChinese = centerPlace.substring(0, 3);
        this.systemCountryObject = this.systemCountryObject.filter(
          (s) => s.label == placeChinese
        );
        this.searchCounty = this.systemCountryObject[0]
          ? this.systemCountryObject[0].id
          : "123456789";
      }
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);

      qs = this.$equalSubstringofFilter("Category", qs, 1);

      if (this.searchCounty) {
        qs = this.$appendEqualFilter("CountryCode", qs, this.searchCounty);
      }
      if (healthCode) {
        qs = this.$appendEqualFilter("HealthCode", qs, healthCode);
      }
      try {
        let { Items, Count } = await window.axios.get(`api/Questionnaire${qs}`);
        this.itemsForChange = JSON.parse(JSON.stringify(Items));

        let keyObj = this.allType.find((s) => s.name == this.selectedType);

        Items = Items.map((s) => {
          s.MajorpreparedShow = s[`${keyObj.MajorpreparedShow}`];
          s.MajorHaveShow = s[`${keyObj.MajorHaveShow}`];
          s.MajorNeedShow = s[`${keyObj.MajorNeedShow}`];
          s.MajorInDateShow = s[`${keyObj.MajorInDateShow}`];
          s.MajorContractShow = s[`${keyObj.MajorContractShow}`];
          s.MajorContract2Show = s[`${keyObj.MajorContract2Show}`];
          s.MajorContract3Show = s[`${keyObj.MajorContract3Show}`];
          return s;
        });

        let countryObj = JSON.parse(JSON.stringify(this.countryAllExist));

        countryObj = countryObj.map((s, i) => ({
          index: i + 1,
          array: Items.filter((k) => k.CountryName == s),
        }));

        this.items = JSON.parse(JSON.stringify(countryObj));

        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  async mounted() {
    //await this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
    "modalItem.MajorpreparedShow"(v) {
      this.modalItem.MajorNeedShow =
        +this.modalItem.MajorpreparedShow - +this.modalItem.MajorHaveShow > 0
          ? +this.modalItem.MajorpreparedShow - +this.modalItem.MajorHaveShow
          : 0;
    },
    "modalItem.MajorHaveShow"(v) {
      this.modalItem.MajorNeedShow =
        +this.modalItem.MajorpreparedShow - +this.modalItem.MajorHaveShow > 0
          ? +this.modalItem.MajorpreparedShow - +this.modalItem.MajorHaveShow
          : 0;
    },
    "modalItem.MajorContractShow"(v) {
      this.modalItem.MajorContract3Show =
        +this.modalItem.MajorContractShow - +this.modalItem.MajorContract2Show >
        0
          ? +this.modalItem.MajorContractShow -
            +this.modalItem.MajorContract2Show
          : 0;
    },
    "modalItem.MajorContract2Show"(v) {
      this.modalItem.MajorContract3Show =
        +this.modalItem.MajorContractShow - +this.modalItem.MajorContract2Show >
        0
          ? +this.modalItem.MajorContractShow -
            +this.modalItem.MajorContract2Show
          : 0;
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 250px 600px ;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 33px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 4px;
    // padding-top: 7px;
  }
}

.my-dark {
  > div {
    background: #333;
    color: white;
  }
}

.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 32px;
  margin-bottom: 12px;
  grid-template-columns: repeat(6, max-content);
  grid-gap: 12px;
  padding-left: 10px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 125px;
  background: #0379fd;
  color: #fff;
}

.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }

  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
.my-light {
  > div {
    background: black;
    color: black;
  }
}
.modal-grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
}
::v-deep .ql-container {
  height: 460px;
}
</style>
